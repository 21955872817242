.Hexagram {
    float: left;
    width: 30%;
}

.Hexagram+.Hexagram {
    margin-left: 5%;
}

@media screen and (max-width: 380px) {
    .Hexagram {
        float: left;
        width: 32%;
    }
    .Hexagram+.Hexagram {
        margin-left: 2%;
    }
}

.Hexagram .Trigram {
    background-color: #f6f6f6;
}

.Hexagram .Trigram .br {
    height: 10px;
    background: white;
}

.Hexagram .Trigram .YinLine,
.Hexagram .Trigram .YangLine {
    background-color: transparent;
    height: 25px;
}

.Hexagram .Trigram .YinLine:after {
    content: '';
    display: block;
    position: relative;
    height: 25px;
    width: 15%;
    top: 0;
    left: 42.5%;
    background-color: white;
}

.HexagramName {
    text-align: center;
    background-color: white;
    font-size: 20px;
    height: 35px;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 800;
    color: #323232;
    letter-spacing: 2px;
}