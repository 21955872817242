.ActionBar {
    margin: 25px auto;
    text-align: center;
    user-select: none;
}

.ActionBar div input {
    width: 3.5em;
    padding: 3px 5px;
    border-radius: 2px;
    background-color: white;
    border: 1px solid gray;
    color: inherit;
}

.ActionBar div input,
.ActionBar div button {
    font-family: inherit;
    font-size: 18px;
    margin: 5px;
    font-weight: 800;
}

@media screen and (max-width: 380px) {
    .ActionBar div input,
    .ActionBar div button {
        font-size: 15px;
    }
}

.ActionBar div button:active {
    background-color: #171717;
}

.ActionBar div button {
    padding: 4px 18px;
    background-color: #3F3F3F;
    color: #e3e3e3;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.1s;
    outline: none;
}